@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: 100vh;
  background: #1e0d30;
}

.odd-bg-gray:nth-child(odd + 1) {
  background-color: #d1d5db; /* Tailwind's gray-300 */
}

.filter-shadow {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
}

.timer-circle {
  transition: stroke 0.3s ease, stroke-dashoffset 0.3s ease;
}

.slide-in {
  animation: slideIn 0.8s ease-out forwards;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fillColor {
  opacity: 0;
  transform: scale(1.5);
  animation: stamp 0.4s forwards;
}

@keyframes stamp {
  0% {
    opacity: 0;
    transform: scale(1.5);
    z-index: 100;
  }
  10% {
    opacity: 0.5;
    transform: scale(4);
    z-index: 100;
    transition: all 0.3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    z-index: auto;
  }
}

@keyframes notificationPopup {
  0% {
    opacity: 0;
    /* transform: translateX(20px); */
    transform: translateY(0);
  }
  20% {
    opacity: 1;
    /* transform: translateX(-100px); */
    transform: translateY(-10px);
    font-size: 2.5rem;
  }
  80% {
    opacity: 1;
    /* transform: translateX(-130px); */
    transform: translateY(-30px);
    font-size: 2.5rem;
  }
  100% {
    opacity: 0;
    /* transform: translateX(-150px); */
    transform: translateY(-50px);
  }
}

.notification-popup {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  color: white;
  font-weight: bold;
  font-size: 2.25rem;
  animation: notificationPopup 0.5s ease-out both;
  pointer-events: none;
}
